nav {
  width:100%;
  height: 50px;
  background: rgba(255,255,255,.6);
  /* border-top: 1px solid rgb(99, 97, 97); */
  /* border-bottom: 1px solid rgba(99,99,99,.6);     */
  position: relative;
  position: sticky;
  z-index: 99;
  top: 0px;
}
nav ul {
  display: flex;
  margin: 0;
  padding: 0 30px;    
  float: right;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  font-family: 'Moon';
  display: block;
  color:#333333;
  padding: 0 20px;
  text-transform: lowercase;
  font-size: 18px;    
  text-decoration: none;
  line-height: 50px;
}
nav ul li a:hover {
    background:#849531;
}
nav ul li a.active {
    /* background: limegreen; */
    background:#56642A;
    color:white;
}

.defaultLink {
    font-family: 'Moon';
    display: block;
    color:#333333;
    padding: 0 20px;
    text-transform: lowercase;
    font-size: 22px;    
    text-decoration: none;
    line-height: 50px; 
}
.defaultLinkActive {
    background:#56642A;
    color:white;
    cursor: pointer;
}
.defaultLink:hover {
    /* background: limegreen; */
    background:#849531;
    color:black;
    cursor: pointer;
}