.projectsSectionRoot {   
  width:100vw;
  height: auto;
  background:#E8EAE3;    
  border:solid 1px transparent;
  margin-top:-300px;
  padding-top:330px; 
  display: grid;
  justify-items: center;
}

.sectionContainer {
  width:700px;
}

.projectsGrid {
  width:100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top:50px;
  margin-bottom: 100px;
}

.projectItem {
  height:125px;
  width: 220px;
  border: solid 1px transparent;
  justify-self: center;
  align-self: center;
  background: white;
  color:black;
  display: grid;
  align-items: center;
  justify-items: center;
}