.headerRoot {
  width:100%;
  height:100px;
  font-family: 'Awaken';

  font-size: 48px;
  text-transform: none;
  border:solid 1px transparent;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.titleParent {
  display: flex;
  flex-direction: row;
}

.titleChar {
  background-color: white;
}

.areaLeft {
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: 30px;
}

.areaMiddle {
  grid-column: 2;
}

.areaRight {
  grid-column: 3;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top:20px;
  margin-right: 45px;
}





// .nameContainer {
//   grid-column: 1;
//   position: absolute;
//   left:50px;
//   top:42px;  
// }

// .nameContainer div {
//   display: inline-block;
// }

// .logoContainer {
//   grid-column: 2;
//   justify-self: end;
//   position:absolute;
//   right: 50px;
//   top:44px;
// }

// .link-container {
//   grid-column: 2;
//   border: solid 1px black;
// }



// .fiveGViewRoot {
//   height: 100%;
//   //background-color: orange;
//   display:grid;
//   grid-template-columns: 1fr;
//   grid-template-rows: auto 1fr;
//   grid-template-areas: 'header' 'content';
//   overflow: hidden;
// }

// .fiveGViewRoot >.headerSection {
//   grid-area: header;
//   //box-sizing: border-box;
//   //background-color: lightgray;
// }

// .fiveGViewRoot > .contentSection {
//   grid-area: content;
//   //box-sizing: border-box;
//   //background-color: purple;
// }