.experiencesSectionRoot {   
  width:100vw;
  height: auto;
  background:#E8EAE3;    
  border:solid 1px transparent;
  margin-top:-300px;
  padding-top:330px; 
  display: grid;
  justify-items: center;
}

.sectionContainer {
  width:700px;
}

h1 {
  margin-bottom: 50px;
  color:black;
  text-transform: uppercase;
  color:#56642A;
}

p {
  margin-top:0px;
  margin-bottom: 10px;
}
hr {
  border-top: 1px solid #ccc;
  width:760px;
  margin-top:20px;
  margin-bottom: 50px;
}
