* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none !important;
}

.coinLink {
  // border-color: white !important;
  // border-style: outset !important;
  // margin-top: 0px;
  // margin-bottom: 0px;
}

.coinlistItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border:solid 1px black;
  font-family:sans-serif;
  font-size: 14px;
  color: black;
  padding-right: 50px;  // this makes room for hover delete button
  margin-bottom: 20px;
  //background-color: gray;
}

.coinlistImage {
  width: 30px;
}

.coinPrice {
  //color:gray;
}

.danger {
  color: red;
}

.success {
  color: green;
}

.deleteIcon {
  display: none;
  position: absolute;
  right: 0px;
}

.coinlistItem:hover .deleteIcon {
  display: block;
}

.coinlistItem:hover {
  //background-color: lightgray;
}