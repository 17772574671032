.bioRoot {
  width: 100vw;
  justify-items: center;
  display: grid;
  background-color: white;
}

.parallaxHomeRoot {
  width:100vw;
  height: 150vh;
  display: grid;
  justify-items: center;
  align-items: end;
  border:solid 1px transparent;
  overflow: visible;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.hero {
  padding:0;
  grid-column: 2;
  grid-row: 1/3;
  font-family: 'Moon-Light';    
  font-size: 124px;
  color: rgba(255, 255, 255, .8);
  border:solid 1px transparent;
  display: grid;
  align-self: flex-start;
  justify-self: flex-end;
  // padding-top:300px;
  // padding-right:200px;
  // background-color: yellow;
}

.particleContainer {
  opacity: 60%;
}

.parallaxNavHeader {
  grid-column: 1/3;
  grid-row: 2;
  padding: 50px;
  border:solid 1px transparent;
  color:rgba(255,255,255,.8);
  font-family: 'Moon-Light';
  font-size:124px;
  justify-self: center;
  border:solid 1px transparent;
}

.parallaxSmallRoot {    
  display: grid;
  width:100vw;
  height: 600px;
  justify-items:center;
  align-items: end;
}

.parallaxSmallRoot  h1 {
  font-family: 'Moon-Light';    
  font-size: 124px;
  padding-top:150px;
  color: rgba(255, 255, 255, .6);    
}

  //height: 100vh;
  //height: calc(var(--vh, 1vh) * 100);

  // grid-template-columns: 1fr;
  // grid-template-rows: 1fr auto;
  // grid-template-areas: 'content' 'footer';

  // @media (orientation: portrait) { display: grid;  }
  // @media (orientation: landscape) { display: none; }