.abilityItemRoot {
  display: grid;
  width:100%;
  height: auto;
  grid-gap: 20px;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: auto;
  border:solid 1px transparent;
  margin: 40px 0px;
}

.sectionContainer {
  width:700px;
}

.gridItem {
  border:solid 1px transparent;
  padding:0px;
}

.category {
  grid-column: 1;
  grid-row: 1;
  font-size: 16px;
  font-weight: 700;
}

.skillList {
  grid-column: 2;
  grid-row: 1;
  font-size: 14px;
  font-weight: 400;
}