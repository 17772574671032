$base: 0.6rem;

.outerContainer {
  position:absolute;
  width:100px;
  height: 100px;
  top: 48%;
  left: 90%;
  transform: translate(-48%, -90%);
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  //border: solid 1px #849531;
  //border: solid 1px #333333;
  background-color: black;
  //opacity: 10%;
  cursor: pointer;
}

.buttonContainer {
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width:100px;
  top: 8px;
  left: 0px;
}

.chevron {
  position: absolute;
  width: $base * 3.5;
  height: $base * 0.8;
  opacity: 0;
  transform: scale(0.3);
  animation: moveChevron 3s ease-out infinite;
}

.chevron:first-child {
  animation: moveChevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: moveChevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
 content: '';
 position: absolute;
 top: 0;
 height: 100%;
 width: 50%;
 background: #56642A;
}

.chevron:before {
 left: 0;
 transform: skewY(30deg);
}

.chevron:after {
 right: 0;
 width: 50%;
 transform: skewY(-30deg);
}

@keyframes moveChevron {
 25% {
  opacity: 1;
	}
 33.3% {
  opacity: 1;
  transform: translateY($base * 3.8);
 }
 66.6% {
  opacity: 1;
  transform: translateY($base * 5.2);
 }
 100% {
  opacity: 0;
  transform: translateY($base * 8) scale(0.5);
 }
}

.defaultLink {
  cursor: pointer;
}

.defaultLinkActive {

}
.defaultLink:hover {

}



