html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //font-family: 'Moon','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-family: sans-serif;
}

body {
  margin: 0;
  padding:0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face { font-family: 'Moon'; src: url('./assets/fonts/Moon2.0-Regular.otf'); font-display: fallback;}
@font-face { font-family: 'Moon-Light'; src: url('./assets/fonts/Moon2.0-Light.otf'); font-display: fallback;}
@font-face { font-family: 'Moon-Bold'; src: url('./assets/fonts/Moon2.0-Bold.otf'); font-display: fallback;}
@font-face { font-family: 'Awaken'; src: url('./assets/fonts/AwakenningPersonalUse-DOLPD.ttf'); font-display: fallback;}

// @font-face { font-family: 'Pier'; src: url('./assets/fonts/PierSans-Regular.otf'); }
// @font-face { font-family: 'Pier-Bold'; src: url('./assets/fonts/PierSans-Bold.otf'); }
// @font-face { font-family: 'Pier-Italic'; src: url('./assets/fonts/PierSans-Italic.otf'); }
// @font-face { font-family: 'Pier-Bold-Italic'; src: url('./assets/fonts/PierSans-BoldItalic.otf'); }
// @font-face { font-family: 'Pixel'; src: url('./assets/fonts/VPPixelPro-Outline.otf'); }
// @font-face { font-family: 'Aqua'; src: url('./assets/fonts/aqua.ttf'); }
// @font-face { font-family: 'Aquire'; src: url('./assets/fonts/Aquire-BW0ox.otf'); }
// @font-face { font-family: 'Aquire-Light'; src: url('./assets/fonts/AquireLight-YzE0o.otf'); }
// @font-face { font-family: 'Aquire-Bold'; src: url('./assets/fonts/AquireBold-8Ma60.otf'); }
// @font-face { font-family: 'Spantaran'; src: url('./assets/fonts/Spantaran-GE2D.ttf'); }
// @font-face { font-family: 'Oasis'; src: url('./assets/fonts/Oasis-BW0JV.ttf'); }
// @font-face { font-family: 'Hacked'; src: url('./assets/fonts/Hacked-KerX.ttf'); }




// @font-face {
//   font-family: "TeleNeoWeb-ExtraBold";
//   src: local("TeleNeoWeb-ExtraBold"),
//     url(./fonts/TeleNeoWeb-ExtraBold.woff2) format("woff2"),
//     url(./fonts/TeleNeoWeb-ExtraBold.woff) format("woff");
//   font-style: normal;
// }

// @font-face {
//   font-family: "TeleNeoWeb-Medium";
//   src: local("TeleNeoWeb-Medium"),
//     url(./fonts/TeleNeoWeb-Medium.woff2) format("woff2"),
//     url(./fonts/TeleNeoWeb-Medium.woff) format("woff");
//   font-style: normal;
// }

// @font-face {
//   font-family: "TeleNeoWeb-Thin";
//   src: local("TeleNeoWeb-Thin"),
//     url(./fonts/TeleNeoWeb-Thin.woff2) format("woff2"),
//     url(./fonts/TeleNeoWeb-Thin.woff) format("woff");
//   font-style: normal;
// }

// @font-face {
//   font-family: "TeleNeoWeb-Bold";
//   src: local("TeleNeoWeb-Bold"),
//     url(./fonts/TeleNeoWeb-Bold.woff2) format("woff2"),
//     url(./fonts/TeleNeoWeb-Bold.woff) format("woff");
//   font-style: normal;
// }