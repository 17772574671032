.navLinksRoot {
  //background-color: orange;
}

.linkContainer {
  font-family:sans-serif;
  font-size: 14px;
  color: black;
  display:flex;  
}

.buttonToLink{
  background: none;
  border: none;
  color: black;
  font-family:sans-serif;
  font-size: 14px;
  padding:0px;
  margin:0px;
  cursor: pointer;
  text-decoration: none;
}

.buttonToLink:hover{
  background: none;
  color: #849531;
  text-decoration: underline;
}

.linkSeparator {
  font-family:sans-serif;
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 1px;
}


//color: #849531;
//color: #849531;