.dashboardRoot {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'header' 'content';

  // @media (orientation: portrait) { display: grid;  }
  // @media (orientation: landscape) { display: none; }
}

.headerArea {
  grid-area: 'header';
  
  height: 58px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 43px;
  //background-color: black;
}

.contentArea {
  grid-area: 'content';
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;  
  //background-color: gray;
}





// .coinbaseWidgetRoot {
//   //background-color: olivedrab;
//   width: 400px;
//   height: 400px;
//   border: solid 1px darkgray;
//   border-radius: 5px;
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-template-rows: auto auto 1fr;
//   padding:20px;
// }

// .entry {
//   grid-row: 1;
//   //background-color: purple;
// }

// .status {
//   grid-row: 2;
//   //color:orange;
//   //background-color: gray;
// }

// .results {
//   grid-row: 3;
//   color:black;
// }