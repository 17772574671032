.experienceItemRoot {
  display: grid;
  height: auto;
  width:100%;
  grid-gap: 0px;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 35px auto;
  border:solid 1px transparent;
  margin-bottom: 20px;
}

.gridItem {
  border:solid 1px transparent;
  padding:0px;
}

.name {
  grid-column: 1;
  grid-row: 1;
  font-size: 16px;
  font-weight: 700;
}

.title {
  grid-column: 2;
  grid-row: 1;
  font-size: 16px;
  font-weight: 700;
}

.date {
  grid-column: 1;
  grid-row: 2;
  font-size: 14px;
}

.description {
  grid-column: 2;
  grid-row: 2;
  font-size: 14px;
}
