.abilitiesSectionRoot {
  width:100vw;
  height: auto;
  border:solid 1px transparent;
  margin-top:-300px;
  padding-top:330px; 
  display: grid;
  justify-items: center;
  margin-bottom: 50px;
}

.sectionContainer {
  width:700px;
}