.appRoot {
  width: 100vw;
  height: 100vh;
  background: white;
  display: grid;
  justify-items: center;
}


  //background-color: gray;
  //height: calc(var(--vh, 1vh) * 100);

// .App {
//   text-align: center;
// }

// .AppLogo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .AppLogo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .AppHeader {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .AppLink {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
