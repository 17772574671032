.profileSectionRoot {
  display: grid;
  width:760px;
  height: auto;
  background:white;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 75px 400px auto;
  border:solid 1px transparent;    
  margin-bottom:100px;
  margin-top:-300px;
  padding-top:330px;    
}

.gridItem {
  color:black;
}

.bioImg {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: url(../../../assets/images/mtb_org.JPG);    
}

.header1 {
  grid-row: 1;
  grid-column: 1/4;
  justify-self: center;
  align-self: center;
  font-family: 'Moon-Light';
  font-size: 72px;
  padding-bottom: 20px;
  display: none;
}

.header2 {
  grid-row: 2;
  grid-column: 1/4;
  justify-self: center;
  align-self: center;
  font-size: 20px;
  max-width: 600px;
  padding-bottom: 20px;    
}

.colLeft {
  grid-row: 3;
  grid-column: 1/2;
}
.colRight {
  grid-row: 3;
  grid-column: 2/4;
  background: #E8EAE3;
  color:black;  
  padding:20px 40px;
}

.colRight h1 {
  font-weight: 700;
  font-size: 16px;
}

.colRight p {
  font-weight: 400;
  font-size: 14px;
}

.footer {
  grid-row: 4;
  grid-column: 1/4;
  max-width: 700px; 
  justify-self: center;
  margin-top:10px;
  font-size: 12px;
}