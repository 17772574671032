* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none !important;
}

.watchListWidget {
  width: 400px;
  height: 300px;
  border: solid 1px darkgray;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  padding:30px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

h2 {
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}